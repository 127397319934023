<template>
  <div style="width: 100%;">
    <div style="width: 100%;height: 60px;background: #031F88;overflow:hidden;">
      <!--   log  -->
      <div style="width: 120px;height: 38px;margin: 10px 30px;">
        <img src="https://oss.baigongbao.com/2021/02/04/Cr3y7bZDaF.png" style="width: 120px;height: 38px;">
      </div>
      <div style="display: flex;justify-content: center;align-content: center;margin-top: -42px;">
        <div style="margin: 2px 5px;width: 22px;height: 22px;">
          <img src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/index/icon4.png" style="width: 22px;height: 22px;">
        </div>
        <div style="font-size: 18px;color: #FFFFFF;font-weight: bold;">知识仓库（文章）</div>
      </div>
    </div>
    <div style="background: #ffffff;width: 1920px;height: 100%;overflow: hidden;display: flex;justify-content: space-around; margin: 0 auto;">
      <filebaase></filebaase>
    </div>
  </div>
</template>

<script>
  import filebaase from "./components/filebase.vue"
  export default {
    name:"",
    components: {
      filebaase
    },
    data() {
      return {
        roomId:0,
        menuId:0
      }
    },

    created:function(){

    },
    mounted: function () {

    },
    methods:{
    }
  }

</script>

<style scoped>

</style>